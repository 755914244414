// BS
@import "bootstrap/scss/functions";

// OVERWRITE
$primary: #448c74;
$btn-padding-x-lg: 2rem;
$btn-padding-y-lg: 1rem;
$btn-font-size-lg: 1rem;
$btn-border-radius-lg: calc(1rem * 1.125);

@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

@import "bootstrap/scss/utilities";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";

@import "bootstrap/scss/buttons";
//@import "bootstrap/scss/card";
//@import "bootstrap/scss/list-group";

@import "bootstrap/scss/utilities/api";